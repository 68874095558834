<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
<!--        <nav class="footer-nav">
          <ul>
            <li>
              <a
                href="https://www.creative-tim.com"
                class="nav-link"
                target="_blank"
                rel="noopener"
                >CREATIVE TIM</a
              >
            </li>
            <li>
              <a
                href="https://www.updivision.com/"
                class="nav-link"
                target="_blank"
                rel="noopener"
                >UPDIVISION</a
              >
            </li>
            <li>
              <a
                href="https://www.creative-tim.com/presentation"
                class="nav-link"
                target="_blank"
                rel="noopener"
                >ABOUT US</a
              >
            </li>
            <li>
              <a
                href="https://blog.creative-tim.com"
                class="nav-link"
                target="_blank"
                rel="noopener"
                >BLOG</a
              >
            </li>
            <li>
              <a
                href="https://www.creative-tim.com/license"
                class="nav-link"
                target="_blank"
                rel="noopener"
                >LICENSES</a
              >
            </li>
          </ul>
        </nav>-->
        <div class="credits ml-auto mr-auto">
          <div class="copyright">
            <i class="fa fa-copyright" style="font-weight: 200"></i> {{ year }}, by
            <a
              href="https://www.giltech.com.vn"
              target="_blank"
              rel="noopener"
              >Gil-Tech, </a
            >
            All rights reserved. Cookie policy
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "main-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
</style>
