export default {
    SHOW_LOADING: function(state){
        state.showCount = state.showCount + 1
        //console.log(state.showCount)
        if(state.showCount > 0)
            state.loading = true;
    },
    HIDE_LOADING: function(state){
        state.showCount = state.showCount - 1
        if(state.showCount <= 0){
            state.showCount = 0;
            state.loading = false;
        }

    }
}