import service from '../services/users-service';

const initialState = {
    list: [],
    user: {},
    meta: {},
    page:{},
    url: null
};

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        list({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.list(params)
                .then(({data, roles}) => {
                    //console.log(roles)
                    data.data.forEach((u) => {
                        if(u.relationships && u.relationships.roles && u.relationships.roles.data
                            && u.relationships.roles.data.length > 0){
                            const role = roles.find(x => x.id === u.relationships.roles.data[0].id);
                            if(role)
                                u.role_name = role.attributes.name
                        }
                    })
                    //console.log(list)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_LIST', data.data);
                    commit('SET_META', data.meta);
                    commit('SET_PAGE', data.meta.page);
                });
        },

        get({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.get(params)
                .then((user) => {
                    console.log(user)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_RESOURCE', user);
                });
        },

        add({commit}, user) {
            delete user.id
            const data = user.attributes;
            console.log(data)
            commit('SHOW_LOADING', null, {root: true})
            return service.add(data)
                .then(
                    () => {
                        console.log(data)
                        commit('HIDE_LOADING', null, {root: true})
                    },
                    error => {
                        commit('HIDE_LOADING', null, {root: true})
                        //console.log(error)
                        return Promise.reject(error);
                    }
                );
        },

        update({commit}, user) {
            delete user.attributes.password
            delete user.attributes.password_confirmation
            const data = {
                data: user
            }
            console.log(data)
            commit('SHOW_LOADING', null, {root: true})
            return service.update(user.id, data)
                .then(
                    () => {
                        commit('HIDE_LOADING', null, {root: true})
                    },
                    error => {
                        commit('HIDE_LOADING', null, {root: true})
                        //console.log(error)
                        return Promise.reject(error);
                    }
                );
        },

        destroy({commit}, whse) {

            commit('SHOW_LOADING', null, {root: true})
            return service.destroy(whse.id).then(
                () => {
                    commit('HIDE_LOADING', null, {root: true})
                },
                error => {
                    commit('HIDE_LOADING', null, {root: true})
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
            ;
        },

        upload({commit}, {warehouse, image}) {
            return service.upload(warehouse, image)
                .then((url) => {
                    commit('SET_URL', url);
                });
        }
    },
    mutations: {
        SET_LIST: (state, list) => {
            state.list = list;
        },
        SET_RESOURCE: (state, warehouse) => {
            state.user = warehouse;
        },
        SET_META: (state, meta) => {
            state.meta = meta;
        },
        SET_URL: (state, url) => {
            state.url = url;
        },
        SET_PAGE: (state, page) => {
            state.page = page;
        }
    },
    getters: {
        list: state => state.list,
        listTotal: state => state.meta.page.total,
        user: state => state.user,
        url: state => state.url
    }
}
