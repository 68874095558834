<template>
  <div class="form-group"
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </slot>
    <slot>
      <div class="datetime-picker">
        <div class="block">
          <el-date-picker
              :model-value="modelValue"
              @update:model-value="$emit('update:modelValue', $event)"
              :type="type"
              v-bind="$attrs"
              :format="format"
              value-format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import {ElDatePicker} from "element-plus";

export default {
  name: "fg-datetime",
  components: {
    [ElDatePicker.name]: ElDatePicker,
  },
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    modelValue: {
      type: [String, Number],
      description: 'Input value'
    },
    dataSource: {
      type: Array
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'DD-MM-YYYY'
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      touched: false,
      focused: false,
      hadError: false,
      selected: ''
    }
  },
  computed: {
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      return false
    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style scoped>
/*.datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.datetime-picker .block {
  margin: 0 30px 0 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.datetime-picker .block:last-child {
  border-right: none;
}
.datetime-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}*/
.form-group >>> .el-input__inner{
  padding: 18px 10px 18px 0px;

}
.datetime-picker .block >>> .el-date-editor.el-input, .el-date-editor.el-input__wrapper {
  width: 100%;
  height: auto;
}
</style>