<template>
  <div class="col-sm-6 mt-4 pagination-info">
    <p class="category" style="font-size: 0.9em;">Hiển thị <b>{{ modelValue.from }}</b> đến <b>{{ modelValue.to }}</b> trong tổng số <b>{{ modelValue.total }}</b> dòng</p>
  </div>
  <div class="col-sm-6 mt-4 text-right">
    <ul class="pagination" :class="paginationClass">
      <li class="page-item prev-page" :class="{disabled: modelValue.currentPage === 1}">
        <a class="page-link" aria-label="Previous" @click="prevPage">
          <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
        </a>
      </li>
      <li class="page-item" :class="{active: modelValue.currentPage === item}" v-for="(item, index) in range(1, modelValue.lastPage)" v-bind:key="item">
        <a class="page-link" v-if="(index > modelValue.currentPage - 5 && index < modelValue.currentPage + 3) || index >= modelValue.lastPage-2" @click="changePage(item)">{{ (index=== modelValue.lastPage-2 && index > modelValue.currentPage + 3) ? '...' : item}}</a>
      </li>
      <li class="page-item next-page" :class="{disabled: modelValue.currentPage === modelValue.lastPage}">
        <a class="page-link" aria-label="Next" @click="nextPage">
          <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'p-pagination',
    props: {
      type: {
        type: String,
        default: 'default',
        description: 'Pagination type (primary|info|danger|default|warning|success)'
      },
      
      modelValue: {
        type: Object,
        description: 'Pagination value'
      }
    },
    computed: {
      paginationClass() {
        return `pull-right pagination-${this.type}`
      },
    },
    data() {
      return {
        defaultPagesToDisplay: 5
      }
    },
    methods: {
      range(min, max) {
        let arr = []
        for (let i = min; i <= max; i++) {
          arr.push(i)
        }
        return arr
      },
      changePage(item) {
        this.$emit('change-page', item)
      },
      nextPage() {
        if (this.modelValue.currentPage < this.modelValue.lastPage) {
          this.$emit('change-page', this.modelValue.currentPage + 1)
        }
      },
      prevPage() {
        if (this.modelValue.currentPage > 1) {
          this.$emit('change-page', this.modelValue.currentPage - 1)
        }
      }
    },
    /*watch: {
      perPage() {
        this.$emit('input', 1)
      },
      total() {
        this.$emit('input', 1)
      }
    }*/
  }
</script>
