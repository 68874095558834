import axios from 'axios';
import FileSaver from 'file-saver'
import authHeader from "./auth-header";

const url = process.env.VUE_APP_API_BASE_URL;

function getStockIn(params) {
    const options = {
        params: params,
        headers: authHeader()
    };
    //console.log(options)
    return axios.get(`${url}/report/getStockInReport`, options)
        .then(response => {
            console.log(response.data)
            return {
                data: response.data
            };
        });
}

function exportStockIn(params) {
    const options = {
        params: params,
        headers: authHeader(),
        responseType: 'blob'
    };
    //console.log(options)
    return axios.post(`${url}/report/exportStockInReport`, {}, options)
        .then(response => {
            FileSaver.saveAs(response.data, `NhapKho_${params.from_date}_${params.to_date}.xlsx`);
        });
}

function getStockOut(params) {
    const options = {
        params: params,
        headers: authHeader()
    };
    //console.log(options)
    return axios.get(`${url}/report/getStockOutReport`, options)
        .then(response => {
            console.log(response.data)
            return {
                data: response.data
            };
        });
}

function exportStockOut(params) {
    const options = {
        params: params,
        headers: authHeader(),
        responseType: 'blob'
    };
    //console.log(options)
    return axios.post(`${url}/report/exportStockOutReport`, {}, options)
        .then(response => {
            FileSaver.saveAs(response.data, `XuatKho_${params.from_date}_${params.to_date}.xlsx`);
        });
}

function getInventory(params) {
    const options = {
        params: params,
        headers: authHeader()
    };
    //console.log(options)
    return axios.get(`${url}/report/getInventoryReport`, options)
        .then(response => {
            console.log(response.data)
            return {
                data: response.data
            };
        });
}

function exportInventory(params) {
    const options = {
        params: params,
        headers: authHeader(),
        responseType: 'blob'
    };
    //console.log(options)
    return axios.post(`${url}/report/exportInventoryReport`, {}, options)
        .then(response => {
            FileSaver.saveAs(response.data, `KiemKe_${params.from_date}_${params.to_date}.xlsx`);
        });
}

function exportInventoryById(params) {
    const options = {
        headers: authHeader(),
        responseType: 'blob'
    };
    //console.log(options)
    return axios.post(`${url}/report/export-inventory/${params.id}`, {}, options)
        .then(response => {
            FileSaver.saveAs(response.data, `KiemKe.xlsx`);
        });
}

function exportClearTagById(params) {
    const options = {
        headers: authHeader(),
        responseType: 'blob'
    };
    //console.log(options)
    return axios.post(`${url}/report/clear-tag-report/${params.id}`, {}, options)
        .then(response => {
            FileSaver.saveAs(response.data, `XoaTag.xlsx`);
        });
}

async function getClearTag(params) {
    let options = {
        params: params,
        headers: authHeader()
    };
    //console.log(options)
    const response = await axios.get(`${url}/kiotviet/fetch-insert-invoice`, options);
    if(response.status === 200 && response.data != null && response.data.data != null){
        options = {
            params: {
                'session': response.data.data.session,
                'date': params.from_date
            },
            headers: authHeader()
        };
        return axios.get(`${url}/report/clear-tags`, options)
            .then(response => {
                //console.log(response.data)
                return {
                    data: response.data
                };
            });
    }
    else {
        return null;
    }
}

async function exportClearTag(params) {
    let options = {
        params: params,
        headers: authHeader()
    };
    const response = await axios.get(`${url}/kiotviet/fetch-insert-invoice`, options);
    //console.log(response);
    if(response.status === 200 && response.data != null && response.data.data != null){
        options = {
            params: {
                'session': response.data.data.session,
                'date': params.from_date
            },
            headers: authHeader(),
            responseType: 'blob'
        };
        return axios.post(`${url}/report/export-clear-tags`, {}, options)
            .then(response => {
                FileSaver.saveAs(response.data, `DoiSoat_${params.branch_name}_${params.from_date}.xlsx`);
            });
    }
    else return null;

}


export default {
    getStockIn,
    exportStockIn,
    getStockOut,
    exportStockOut,
    getInventory,
    exportInventory,
    exportInventoryById,
    getClearTag,
    exportClearTag,
    exportClearTagById
};

