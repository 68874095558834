<template>
  <div class="user">
    <div class="photo avatar-image" :style="{'background-image': `url('${profileImage}')`,}" />
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           Hi!  <strong>{{ user.name }}</strong>
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="#" @click="goToProfile">
                <span class="sidebar-mini-icon">P</span>
                <span class="sidebar-normal">Cá nhân</span>
              </a>
            </li>
            <li>
              <a href="#" @click="logout">
                <span class="sidebar-mini-icon">L</span>
                <span class="sidebar-normal">Đăng xuất</span>
              </a>
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from "vuex";
  export default {
    
    computed: {
      ...mapState({
        user: state => state.auth.user,
      }),
      profileImage() {
        return this.avatar ? this.avatar : "/img/placeholder.jpg";
      },
    },
    data() {
      return {
        isClosed: true,
        avatar: null,
        title: "Profile"
      }
    },
    async created() {
      /*this.$store.watch(
        () => this.$store.getters["profile/me"],
        (me) => {
          this.title = me.name;
          this.avatar = me.profile_image;
        }
      );
      await this.$store.dispatch("profile/me");*/
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      async logout() {
        try {
          await this.$store.dispatch('auth/logout').then(
              (data) => {
                console.log(data)
                this.$router.push('/login');
              },
              (error) => {
                //console.log(error)
                let message = error.response.data.message;
                this.$notify({
                  text: message,
                  type: 'warn',
                });
              }
          );
        } catch (e) {
          console.log(e);
          this.$notify({
            text: e.response.data.message,
            type: 'warn',
          });
        }
      },
      goToProfile() {
        this.$router.push({name: "User Profile"});
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
