
import { createApp } from 'vue';
import './pollyfills'
import VueRouter from 'vue-router'
//import VueRouterPrefetch from 'vue-router-prefetch'
//import lang from 'element-plus/lib/locale/lang/en'
//import locale from 'element-plus/lib/locale'
import App from './App.vue'
import axios from "axios";
import VueAxios from "vue-axios";

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import formatter from 'src/util/formatter'
import SideBar from './components/UIComponents/SidebarPlugin'

import router from './router'
// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import 'element-plus/dist/index.css'
//import './assets/css/bootstrap.min.css'

import 'src/assets/custom.css';

//import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
import store from "./store";
//import { FontAwesomeIcon } from './plugins/font-awesome'
//import VueMeta from 'vue-meta'
import IsDemo from './isDemo'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Notifications from '@kyvg/vue3-notification'
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import LaravelPermissionToVueJS from './laravel-permission-to-vuejs'
import Moment from 'moment'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const appInstance = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    appInstance.component(key, component)
}
SideBar.register(appInstance)
//appInstance.component("font-awesome-icon", FontAwesomeIcon)
appInstance.use(VueRouter)
//appInstance.use(VueRouterPrefetch)
appInstance.use(router)
appInstance.use(store)
appInstance.use(GlobalDirectives)
appInstance.use(formatter)
appInstance.use(GlobalComponents)
//appInstance.use(SideBar, {sidebarLinks: sidebarLinks})
appInstance.use(IsDemo)
//appInstance.use(VueMeta)
appInstance.use(LoadingPlugin)
appInstance.use(Notifications)
appInstance.use(ConfirmDialog)
appInstance.use(LaravelPermissionToVueJS)
appInstance.use(Moment)
appInstance.use(VueAxios, axios)
appInstance.mount("#app");