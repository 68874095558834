<template>
  <div class="form-group"
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </slot>
    <slot>
      <el-input
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          v-bind="$attrs"
      />
    </slot>
  </div>
</template>

<script>
import {ElInput} from "element-plus";

export default {
  name: "fg-textarea",
  components: {
    [ElInput.name]: ElInput,
  },
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    modelValue: {
      type: [String, Number],
      description: 'Input value'
    },
    dataSource: {
      type: Array
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      touched: false,
      focused: false,
      hadError: false,
      selected: ''
    }
  },
  computed: {
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      return false
    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style scoped>


</style>