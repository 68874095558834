import axios from 'axios';
import Jsona from 'jsona';
import authHeader from "./auth-header";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
    const options = {
        params: params,
        headers: authHeader()
    };
    //console.log(options)
    return axios.get(`${url}/user-roles`, options)
        .then(response => {
            //console.log(response.data.data)
            return {
                data: response.data
            };
        });
}

function get(id) {
    const options = {
        params: {
            include: 'permissions'
        },
        headers: authHeader()
    };
    return axios.get(`${url}/user-roles/${id}`, options)
        .then(response => {
            let role = response.data.data;
            delete role.links;
            return role;
        });
}

function add(role) {
    console.log(JSON.stringify(role))
    return axios.post(`${url}/user-roles`, role, {
        headers: authHeader()
    }).then(response => {
        //console.log(response)
        return response.data.data;
    });
}

function update(id, role) {
    console.log(JSON.stringify(role))
    return axios.patch(`${url}/user-roles/${id}`, role, {headers: authHeader()})
        .then(response => {
            return jsona.deserialize(response.data);
        });
}

function destroy(id) {
    return axios.delete(`${url}/user-roles/${id}`, {headers: authHeader()})
        .then(response => {
            return response;
        });
}

function upload(user, image) {
    const bodyFormData = new FormData();
    bodyFormData.append('attachment', image);

    return axios.post(`${url}/uploads/users/${user.id}/profile-image`, bodyFormData)
        .then(response => {
            return response.data.url;
        });
}

export default {
    list,
    get,
    add,
    update,
    destroy,
    upload
};

