import axios from 'axios';
import Jsona from 'jsona';
import authHeader from "./auth-header";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
    const options = {
        params: params,
        headers: authHeader()
    };
    //console.log(options)
    return axios.get(`${url}/items`, options)
        .then(response => {
            //console.log(response.data.data)
            return {
                data: response.data
            };
        });
}

function get(id) {
    const options = {
        params: {
            include: 'category,unit,images,model,styles'
        },
        headers: authHeader()
    };
    return axios.get(`${url}/items/${id}`, options)
        .then(response => {
            let item = response.data;
            //delete whse.links;
            return item;
        });
}

function add(data) {
    return axios.post(`${url}/items`, data, {
        headers: authHeader()
    }).then(response => {
        /*const image = {
            data: [payload.image]
        }
        console.log(image)
        return addImage(response.data.data.id, image);*/
        return response.data.data;
    });
}

function update(id, store) {
    //console.log(JSON.stringify(whse))
    return axios.patch(`${url}/items/${id}`, store, {headers: authHeader()})
        .then(response => {
            return jsona.deserialize(response.data);
        });
}

function destroy(id) {
    return axios.delete(`${url}/items/${id}`, {headers: authHeader()})
        .then(response => {
            return response;
        });
}

function sync() {
    return axios.get(`${url}/kiotviet/fetch-insert-products`, {headers: authHeader(), params: {last_item: 10000}})
        .then(response => {
            return response;
        });
}

function addImage(id, data) {
    console.log(JSON.stringify(data))
    return axios.post(`${url}/items/${id}/relationships/images`, data, {
        headers: authHeader()
    }).then(response => {
        return jsona.deserialize(response.data);
    });
}
function getStyles() {
    const options = {
        headers: authHeader()
    };
    //console.log(options)
    return axios.get(`${url}/styles`, options)
        .then(response => {
            //console.log(response.data.data)
            return {
                data: response.data
            };
        });
}

export default {
    list,
    get,
    add,
    update,
    destroy,
    sync,
    addImage,
    getStyles
};

