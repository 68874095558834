<template>
  <div class="wrapper">
    <side-bar
      :active-color="activeColor"
      :background-color="backgroundColor"
      type="sidebar"
    >
      <user-menu></user-menu>
      <template #links>
<!--        <sidebar-item
          :link="{
            name: 'Trang chủ',
            icon: 'nc-icon nc-bank',
            path: '/admin/overview',
          }"
        >
        </sidebar-item>-->
        <sidebar-item

          :link="{ name: 'Quản trị hệ thống', icon: 'fa fa-cogs' }"
        >
          <sidebar-item v-if="can('view-license')"
            :link="{
              name: 'Cấp tài khoản',
              path: '/license/license-list',
            }"
          />
          <sidebar-item v-if="can('view-role')"
            :link="{
              name: 'Phân quyền',
              path: '/system-management/user-role',
            }"
          />
          <sidebar-item v-if="can('view-store')"
            :link="{
              name: 'Cửa hàng',
              path: '/store/store-list',
            }"
          />
          <sidebar-item v-if="can('view-branch')"
            :link="{
              name: 'Chi nhánh',
              path: '/branch/branch-list',
            }"
          />
          <sidebar-item v-if="can('view-user')"
            :link="{
              name: 'Quản lý nhân viên',
              path: '/system-management/user-list',
            }"
          />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Danh mục hàng hóa',
            icon: 'fa fa-server',
          }"
        >
          <sidebar-item v-if="can('view-warehouse')"
              :link="{
              name: 'Kho hàng',
              path: '/warehouse/warehouse-list',
            }"
          />
          <sidebar-item v-if="can('view-category')"
              :link="{
              name: 'Loại sản phẩm',
              path: '/category/category-list',
            }"
          />
          <sidebar-item v-if="can('view-unit')"
              :link="{
              name: 'Quy cách',
              path: '/unit/unit-list',
            }"
          />
          <sidebar-item v-if="can('view-price')"
              :link="{
              name: 'Bảng giá',
              path: '/price/price-list',
            }"
          />
          <sidebar-item v-if="can('view-item')"
              :link="{
              name: 'Sản phẩm',
              path: '/item/item-list',
            }"
          />
          <sidebar-item v-if="can('view-ean')"
              :link="{
              name: 'Mã vạch-Nhãn Sản phẩm',
              path: '/ean/ean-list',
            }"
          />
        </sidebar-item>
        <sidebar-item v-if="can('view-supplier') || can('view-customer')"

            :link="{ name: 'Danh mục NCC-KH', icon: 'nc-icon nc-badge' }"
        >
          <sidebar-item v-if="can('view-supplier')"
             :link="{
              name: 'Nhà cung cấp',
              path: '/supplier/supplier-list',
            }"
          />
          <sidebar-item v-if="can('view-customer')"
            :link="{
              name: 'Khách hàng',
              path: '/customer/customer-list',
            }"
          />
        </sidebar-item>
        <sidebar-item v-if="can('view-purchase') || can('view-sale') || can('view-refund-purchase') || can('view-refund-customer')"

            :link="{ name: 'Mua bán', icon: 'nc-icon nc-basket' }"
        >
          <sidebar-item
                        :link="{
              name: 'Mua hàng',
              path: '/purchase/purchase-list',
            }"
          />
          <sidebar-item
                        :link="{
              name: 'Bán hàng',
              path: '/sale/sale-list',
            }"
          />
          <sidebar-item
                        :link="{
              name: 'Trả hàng ncc',
              path: '/refund/purchase-list',
            }"
          />
          <sidebar-item
                        :link="{
              name: 'Khách hàng trả',
              path: '/refund/refund-list',
            }"
          />
        </sidebar-item>
        <sidebar-item v-if="can('view-stockin') || can('view-stockout') || can('view-move')"
          :link="{
            name: 'Quản lý kho',
            icon: 'fa fa-object-group'
          }"
        >
          <sidebar-item v-if="can('view-stockin')"
              :link="{
              name: 'Nhập kho',
              path: '/stockin/stockin-list',
            }"
          />
          <sidebar-item v-if="can('view-stockout')"
              :link="{
              name: 'Xuất kho',
              path: '/stockout/stockout-list',
            }"
          />
          <sidebar-item v-if="can('view-move')"
              :link="{
              name: 'Chuyển hàng',
              path: '/move/move-list',
            }"
          />
          <sidebar-item
              :link="{
              name: 'Xóa tag',
              path: '/clear-tag/clear-tag-list',
            }"
          />
        </sidebar-item>

        <sidebar-item v-if="can('view-inventory')"
          :link="{
            name: 'Kiểm kê',
            icon: 'fa fa-cubes',
            path: '/inventory/inventory-list',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Báo cáo',
            icon: 'fa fa-bar-chart'
          }"
        >
          <sidebar-item v-if="can('rpt-stockin')"
              :link="{
            name: 'Báo cáo nhập kho',
            icon: 'nc-icon nc-planet',
            path: '/report/stock-in',
          }"
          />
          <sidebar-item v-if="can('rpt-stockout')"
              :link="{
            name: 'Báo cáo xuất kho',
            icon: 'nc-icon nc-planet',
            path: '/report/stock-out',
          }"
          />
          <sidebar-item v-if="can('rpt-stock')"
              :link="{
            name: 'Tồn kho hệ thống',
            icon: 'nc-icon nc-planet',
            path: '/report/general',
          }"
          />
          <sidebar-item v-if="can('rpt-inventory')"
              :link="{
            name: 'Báo cáo kiểm kê',
            icon: 'nc-icon nc-planet',
            path: '/report/inventory-rpt',
          }"
          />
          <sidebar-item v-if="can('rpt-shift-check')"
                          :link="{
            name: 'Đối soát',
            icon: 'nc-icon nc-planet',
            path: '/report/shift-check-rpt',
          }"
          />
        </sidebar-item>
<!--        <sidebar-item
          :link="{
            name: 'Upgrade to PRO',
            icon: 'fa fa-download',
            target: '_blank',
            isRoute: true,
            path:
              'https://www.creative-tim.com/product/vue-paper-dashboard-pro-laravel',
          }"
          class="btn-sm btn-danger"
        ></sidebar-item>-->
      </template>
    </side-bar>
<!--    <sidebar-share
      :background-color.sync="backgroundColor"
      :active-color.sync="activeColor"
    >
    </sidebar-share>-->

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "src/components/UIComponents/SidebarPlugin/UserMenu";
//import SidebarItem from "../../UIComponents/SidebarPlugin/SidebarItem.vue";
//import SidebarShare from "./SidebarSharePlugin";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
    //SidebarItem,
    //SidebarShare,
  },
  data() {
    return {
      backgroundColor: "primary",
      activeColor: "success",
    };
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
