import axios from 'axios';
import authHeader from "./auth-header";

const url = process.env.VUE_APP_API_BASE_URL;

class AuthService {
  login(user) {
    console.log(user)
    return axios.post(url + '/login', {
        email: user.email,
        password: user.password
      }, {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        }
      })
      .then(response => {
        //console.log(response)
        if (response.data.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data.data));
          //console.log(localStorage)
        }
        //console.log('loadPermission');
        return axios.get(`${url}/get-permissions`, {headers: authHeader()}).then(
          rs => {
            localStorage.setItem('jsPermissions', JSON.stringify(rs.data));
            console.log(rs.data);
            return response.data.data;
          }
        );        
      });
      
  }

  logout() {
    return axios.post(url + '/logout', {}, {
      headers: authHeader()
    }).then(response => {
      console.log(response)
      localStorage.removeItem('user');
      localStorage.removeItem('jsPermissions');
      return true;
    });
  }

  forceLogout() {
    return axios.post(url + '/force-logout', {}, {
      headers: authHeader()
    }).then(response => {
      console.log(response)
      localStorage.removeItem('user');
      localStorage.removeItem('jsPermissions');
      return true;
    });
  }

  register(user) {
    return axios.post(url + '/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();