import service from '../services/warehouse-service';

const initialState = {
    list: [],
    warehouse: {},
    meta: {},
    page:{},
    url: null
};

export const warehouse = {
    namespaced: true,
    state: initialState,
    actions: {
        list({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.list(params)
                .then(({data}) => {
                    //console.log(list)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_LIST', data.data);
                    if(data.meta){
                        commit('SET_META', data.meta);
                        if(data.meta.page)
                            commit('SET_PAGE', data.meta.page);
                    }
                });
        },

        get({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.get(params)
                .then((warehouse) => {
                    console.log(warehouse)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_RESOURCE', warehouse);
                });
        },

        add({commit}, whse) {
            delete whse.id
            const data = {
                data: whse
            }
            commit('SHOW_LOADING', null, {root: true})
            return service.add(data)
                .then(
                    () => {
                        commit('HIDE_LOADING', null, {root: true})
                    },
                    error => {
                        commit('HIDE_LOADING', null, {root: true})
                        //console.log(error)
                        return Promise.reject(error);
                    }
                );
        },

        update({commit}, whse) {
            const data = {
                data: whse
            }
            commit('SHOW_LOADING', null, {root: true})
            return service.update(whse.id, data)
                .then(
                    () => {
                        commit('HIDE_LOADING', null, {root: true})
                    },
                    error => {
                        commit('HIDE_LOADING', null, {root: true})
                        //console.log(error)
                        return Promise.reject(error);
                    }
                );
        },

        destroy({commit}, whse) {

            commit('SHOW_LOADING', null, {root: true})
            return service.destroy(whse.id).then(
                () => {
                    commit('HIDE_LOADING', null, {root: true})
                },
                error => {
                    commit('HIDE_LOADING', null, {root: true})
                    //console.log(error)
                    return Promise.reject(error);
                }
            );;
        },

        upload({commit}, {warehouse, image}) {
            return service.upload(warehouse, image)
                .then((url) => {
                    commit('SET_URL', url);
                });
        }
    },
    mutations: {
        SET_LIST: (state, list) => {
            state.list = list;
        },
        SET_RESOURCE: (state, warehouse) => {
            state.warehouse = warehouse;
        },
        SET_META: (state, meta) => {
            state.meta = meta;
        },
        SET_URL: (state, url) => {
            state.url = url;
        },
        SET_PAGE: (state, page) => {
            state.page = page;
        }
    },
    getters: {
        list: state => state.list,
        listTotal: state => state.meta.page.total,
        warehouse: state => state.warehouse,
        url: state => state.url
    }
}

