//import Vue from 'vue';
//import Vuex from 'vuex';

import {auth} from "./modules/auth-module";
//import alerts from "./modules/alerts-module";
//import profile from "./modules/profile-module";
import {user} from "./modules/users-module";
import {userRole} from "./modules/role-module"
import state from './state'
import mutations from './mutations'
import {warehouse} from "./modules/warehouse-module"
import {store} from "./modules/store-module"
import {category} from "./modules/category-module"
import {unit} from "./modules/unit-module"
import {item} from "./modules/item-module"
import {stockin} from "./modules/stockin-module"
import {stockout} from "./modules/stockout-module"
import {inventory} from "./modules/inventory-module"
import {move} from "./modules/move-module"
import {itemmodel} from "./modules/itemmodel-module"
import {price} from "./modules/price-module"
import {ean} from "./modules/ean-module"
import {customer} from "./modules/customer-module"
import {supplier} from "./modules/supplier-module"
import {sale} from "./modules/sale-module"
import {report} from "./modules/report-module"
import {license} from "./modules/license-module"
import {branch} from "./modules/branch-module"
import {clear_tag} from "./modules/clear-tag-module"

import { createStore } from 'vuex';

export default createStore({
  state,
  mutations,
  actions:{},
  modules: {
    auth,
    userRole,
    //profile,
    user,
    store,
    warehouse,
    category,
    unit,
    item,
    stockin,
    stockout,
    inventory,
    move,
    itemmodel,
    price,
    ean,
    customer,
    supplier,
    sale,
    report,
    license,
    branch,
    clear_tag
  }
});

/*Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    reset,
    warehouse
  }
});
*/