<template>
  <div class="form-group"
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </slot>


    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
    </div>
    <slot>
      <input
          ref="inputField"
          :value="modelValue"
          v-bind="$attrs"
          @input="$emit('update:modelValue', $event.target.value)"
          class="form-control"
          :required="required"
          :class="[{'is-valid': hasSuccess}, inputClasses, {'is-invalid': error}]"
          aria-describedby="addon-right addon-left">
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
          <span class="input-group-text" style="cursor: pointer" @click="iconClick">
              <slot name="addonRight">
                  <i :class="addonRightIcon"></i>
              </slot>
          </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div class="invalid-feedback error-text" :class="{'mt-2': hasIcon}" v-if="error">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>

export default {
  inheritAttrs: false,
  name: 'fg-input',
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    modelValue: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon'
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    },
    defaultFocus: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false
    }
  },
  computed: {
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      const {addonRight, addonLeft} = this.$slots
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
    }
  },
  methods: {
    iconClick() {
      this.$emit('on-icon-click')
    }
  },
  created() {
    this.$watch('error', (newVal) => {
      if (newVal) {
        this.hadError = true;
      }
    }, {immediate: true});
  },
  mounted() {
    if(this.defaultFocus)
      this.$refs.inputField.focus();
  }
}
</script>
<style scoped>
.error-text {
  display: block;
}
</style>
