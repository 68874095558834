import { createApp } from 'vue'
const app = createApp({})

const isDemo = {
  install: () => {
    app.config.globalProperties.$isDemo = process.env.VUE_APP_IS_DEMO || 0;
  }
}

export default isDemo
