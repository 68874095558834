import axios from 'axios';
import Jsona from 'jsona';
import authHeader from "./auth-header";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  params['include'] = 'roles';
  const options = {
    params: params,
    headers: authHeader()
  };
  //console.log(options)
  return axios.get(`${url}/users`, options)
      .then(response => {
        //console.log(response.data.data)
        return {
          data: response.data,
          roles: response.data.included
        };
      });
}

function get(id) {
  const options = {
    headers: authHeader()
  };

  return axios.get(`${url}/users/${id}?include=roles,store,license,branch`, options)
    .then(response => {
      return response.data.data;
    });
}

function add(user) {
  return axios.post(`${url}/register`, user, {
    headers: authHeader()
  }).then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(id, user) {
  console.log(JSON.stringify(user))

  return axios.patch(`${url}/users/${id}`, user, {headers: authHeader()})
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/users/${id}`, options);
}

function upload(user, image) {
  const bodyFormData = new FormData();
  bodyFormData.append('attachment', image);

  return axios.post(`${url}/uploads/users/${user.id}/profile-image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload
};

