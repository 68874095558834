import {parse, format} from 'date-fns';

export default {
    install: (app) => {
        app.config.globalProperties.formatTime = function (date) {
            return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd-MM-yyyy HH:mm:ss')
        },
        app.config.globalProperties.formatTimeShort = function (date) {
            return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd-MM-yyyy')
        },
        app.config.globalProperties.formatTimeBy = function (date, formatter) {
            return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), formatter)
        }
    }
}