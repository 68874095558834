<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i
            class="fa fa-chevron-right text-center visible-on-sidebar-mini"
          ></i>
          <i
            class="fa fa-chevron-left text-center visible-on-sidebar-regular"
          ></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#pablo">Gil Smart Dashboard </a>
    </div>

    <template v-slot:navbar-menu>
<!--      <a
        href="https://vue-paper-dashboard-laravel.creative-tim.com/documentation"
        target="_blank"
        class="btn btn-danger mt-0 mr-2"
        style="background-color: #6bd098"
        id="docs"
      >
        <i class="fas fa-file-alt mr-2"></i>Documentation</a
      >

      <a
        href="https://www.creative-tim.com/product/vue-paper-dashboard-laravel"
        target="_blank"
        class="btn btn-danger mt-0 mr-2"
        style="background-color: #ef8157"
        id="downb"
      >
        <i class="fas fa-download mr-2"></i>Download now</a
      >-->

      <form>
        <div class="input-group no-border">
          <input
            type="text"
            value=""
            class="form-control"
            placeholder="Search..."
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="fa fa-search"></i>
            </div>
          </div>
        </div>
      </form>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link btn-magnify" href="#pablo">
            <i class="fa fa-windows"></i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <drop-down
          icon="fa fa-bell-o"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <a class="dropdown-item" href="#">Thông báo mới</a>
          <a class="dropdown-item" href="#">Cảnh báo tồn kho</a>
        </drop-down>
        <drop-down
          icon="fa fa-cog"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <a class="dropdown-item" href="#" @click="goToProfile">Profile</a>
          <a class="dropdown-item" @click="logout" href="#">Đăng xuất</a>
        </drop-down>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
    };
  },
  methods: {
    goToProfile() {
      this.$router.push({ name: "User Profile" });
    },
    async logout() {
      try {
        await this.$store.dispatch('auth/logout').then(
            (data) => {
              console.log(data)
              this.$router.push('/login');
            },
            (error) => {
              //console.log(error)
              let message = error.response.data.message;
              this.$notify({
                text: message,
                type: 'warn',
              });
            }
        );
      } catch (e) {
        console.log(e);
        this.$notify({
          text: e.response.data.message,
          type: 'warn',
        });
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
};
</script>
<style>
#docs:hover,
#docs:active,
#docs:focus {
  background-color: #6bd098 !important;
}
#downb:hover,
#downb:active,
#downb:focus {
  background-color: #ef8157 !important;
}
</style>
