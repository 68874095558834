<template>
  <footer class="footer">
    <div class="container-fluid">

      <div class="copyright">
        <i class="fa fa-copyright" style="font-weight: 200"></i> {{ year }}, by
        <a
            href="https://www.giltech.com.vn"
            target="_blank"
            rel="noopener"
        >Gil-Tech, </a
        >
        All rights reserved. Cookie policy
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
</style>
