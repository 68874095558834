<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="header-body text-center" style="">
                  <div class="row justify-content-center">
                    <div class="text-center">
<!--                      <h2 class="text-white">Đăng nhập trang quản lý Gil Smart</h2>-->
<!--                      <p class="text-lead text-white">Log in to see how you can go from frontend to fullstack in an instant with an API-based Laravel backend.</p>-->
                    </div>

<!--                    <div class="text-white">
                      <h3 class="text-white"><strong>User test:</strong></h3>
                      <div>Username <b>admin@jsonapi.com</b> Password <b>secret</b></div>
                    </div>-->
                  </div>
            </div>
            <div class="col-lg-5 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <template v-slot:header><h3 class="header text-center" style="margin-bottom: 0">Đăng nhập</h3></template>


                  <fg-input v-model="email" addon-left-icon="nc-icon nc-single-02" placeholder="Nhập email"/>
                  <validation-error :errors="apiValidationErrors.email" />
                  <fg-input v-model="password" addon-left-icon="nc-icon nc-key-25" placeholder="Nhập mật khẩu" type="password"/>
                  <validation-error :errors="apiValidationErrors.password" />
                  <template v-slot:footer>
                    <div>
                      <p-button native-type="submit" type="warning" round block class="mb-3">Gửi</p-button>

                      <!--                    <div class="pull-left">
                                            <h6><a href="/password/reset" class="link footer-link">Forgot Password?</a></h6>
                                          </div>-->
                    </div>
                  </template>

                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/chibest-background.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar.vue'
  import AppFooter from './Layout/AppFooter.vue'
  import formMixin from "@/mixins/form-mixin";
  import ValidationError from "src/components/UIComponents/ValidationError.vue";

export default {
  mixins: [formMixin],
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Button.name]: Button,
    ValidationError
  },
  data() {
    return {
      email: "",
      password: "",
    }
  },
  computed: {
    loggedIn() {
      console.log(this.$store.state.auth.status.loggedIn)
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    async login() {
      const user = {
        email: this.email,
        password: this.password
      }


      try {
        await this.$store.dispatch("auth/login", user).then(
            (data) => {
              console.log(data)
              this.$router.push("/");
            },
            (error) => {
              //console.log(error)
              let message = error.response.data.message;
              this.$notify({
                text: message,
                type: 'warn',
              });
            }
        );
      } catch (e) {
        this.$notify({
          text:'Invalid credentials!',
          type: 'warn',
        });
        this.setApiValidation(e.response.data.errors)
      }
    }
  },
  beforeUnmount() {
    this.closeMenu()
  }
}
</script>
<style>
</style>
