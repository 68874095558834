import AuthService from '../services/auth-service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, user) {
            commit('SHOW_LOADING', null, {root: true})
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    commit('HIDE_LOADING', null, {root: true})
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    commit('HIDE_LOADING', null, {root: true})
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            commit('SHOW_LOADING', null, {root: true})
            return AuthService.logout().then(
                data => {
                    console.log(data);
                    commit('HIDE_LOADING', null, {root: true})
                    commit('logout');

                    return Promise.resolve(data);
                },
                error => {
                    commit('HIDE_LOADING', null, {root: true})
                    console.log(error)
                    return AuthService.forceLogout().then(
                        data => {
                            console.log(data);
                            commit('HIDE_LOADING', null, {root: true})
                            commit('logout');

                            return Promise.resolve(data);
                        },
                        error => {
                            commit('HIDE_LOADING', null, {root: true})
                            console.log(error)
                            return Promise.reject(error);
                        }
                    );
                }
            );
        },
        forceLogout({commit}) {
            commit('SHOW_LOADING', null, {root: true})
            return AuthService.forceLogout().then(
                data => {
                    console.log(data);
                    commit('HIDE_LOADING', null, {root: true})
                    commit('logout');

                    return Promise.resolve(data);
                },
                error => {
                    commit('HIDE_LOADING', null, {root: true})
                    console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        register({commit}, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};