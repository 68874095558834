import service from '../services/clear-tag-service';

const initialState = {
    list: [],
    model: {},
    items: [],
    meta: {},
    page:{},
    url: null
};

export const clear_tag = {
    namespaced: true,
    state: initialState,
    actions: {
        list({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.list(params)
                .then(({data}) => {
                    //console.log(list)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_LIST', data.data);
                    commit('SET_META', data.meta);
                    commit('SET_PAGE', data.meta.page);
                });
        },

        get({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.get(params)
                .then((item) => {
                    //console.log(item)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_RESOURCE', item.data);
                    //commit('SET_META', {included: item.included});
                });
        },

        add({commit}, item) {
            delete item.id
            console.log(JSON.stringify(item))
            const data = {
                data: item
            }
            commit('SHOW_LOADING', null, {root: true})
            return service.add(data)
                .then(
                    () => {
                        commit('HIDE_LOADING', null, {root: true})
                    },
                    error => {
                        commit('HIDE_LOADING', null, {root: true})
                        //console.log(error)
                        return Promise.reject(error);
                    }
                );
        },

        update({commit}, item) {
            const data = {
                data: item
            }
            commit('SHOW_LOADING', null, {root: true})
            return service.update(item.id, data)
                .then(
                    () => {
                        commit('HIDE_LOADING', null, {root: true})
                    },
                    error => {
                        commit('HIDE_LOADING', null, {root: true})
                        //console.log(error)
                        return Promise.reject(error);
                    }
                );
        },

        updateStatus({commit}, stockIn) {
            const data = {
                data: stockIn
            }
            commit('SHOW_LOADING', null, {root: true})
            return service.updateStatus(stockIn.id, data).then(
                () => {
                    commit('HIDE_LOADING', null, {root: true})
                },
                error => {
                    commit('HIDE_LOADING', null, {root: true})
                    //console.log(error)
                    return Promise.reject(error);
                }
            );;
        },

        getItems({commit}, stockId) {
            commit('SHOW_LOADING', null, {root: true})
            return service.getItems(stockId)
                .then((item) => {
                    console.log(item)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_ITEMS', item.data);
                });
        },

        upload({commit}, {warehouse, image}) {
            return service.upload(warehouse, image)
                .then((url) => {
                    commit('SET_URL', url);
                });
        },
    },
    mutations: {
        SET_LIST: (state, list) => {
            state.list = list;
        },
        SET_RESOURCE: (state, model) => {
            state.model = model;
        },
        SET_ITEMS: (state, items) => {
            state.items = items;
        },
        SET_META: (state, meta) => {
            state.meta = meta;
        },
        SET_URL: (state, url) => {
            state.url = url;
        },
        SET_PAGE: (state, page) => {
            state.page = page;
        }
    },
    getters: {
        list: state => state.list,
        listTotal: state => state.meta.page.total,
        model: state => state.model,
        items: state => state.items,
        url: state => state.url,
        page: state => state.page
    }
}

