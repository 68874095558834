<template>
  <div class="form-group"
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </slot>
    <slot>
      <el-select
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
          class="select-default"
          v-bind="$attrs"
          :clearable="clearable"
          @change="onChange"
          >
        <el-option
            class="select-default"
            v-for="item in dataSource"
            :key="item[dvalue]"
            :label="item.attributes[dtext]"
            :value="item[dvalue]">
        </el-option>
      </el-select>
    </slot>
  </div>
</template>

<script>
import {ElSelect, ElOption} from 'element-plus';
export default {
  inheritAttrs: false,
  name: 'fg-dropdown',
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    modelValue: {
      type: [String, Number],
      description: 'Input value'
    },
    dataSource: {
      type: Array
    },
    dtext: {
      type: String
    },
    dvalue: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
  },
  emits: ['update:modelValue'],
  /*watch: {
    modelValue(newValue, oldValue){
      console.log(newValue + ' ' + oldValue)
    }
  },*/
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false,
      selected: ''
    }
  },
  computed: {
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIcon() {
      return false
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change-val', value);
    },
  },
  created() {
  }
}
</script>

<style scoped>
  .form-group >>> .el-select{
    width: 100%;
  }
  .form-group >>> .el-input__inner{
    padding: 18px 10px 18px 0px;

  }
</style>