import service from '../services/report-service';

const initialState = {
    stockIns: [],
    stockOuts: [],
    inventories: [],
    clearTags: [],
};

export const report = {
    namespaced: true,
    state: initialState,
    actions: {
        //StockIn
        getStockIn({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.getStockIn(params)
                .then(({data}) => {
                    console.log(data)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_STOCK_IN', data);
                });
        },

        exportStockIn({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            service.exportStockIn(params)
            commit('HIDE_LOADING', null, {root: true})
        },

        //StockOut
        getStockOut({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.getStockOut(params)
                .then(({data}) => {
                    console.log(data)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_STOCK_OUT', data);
                });
        },
        exportStockOut({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            service.exportStockOut(params)
            commit('HIDE_LOADING', null, {root: true})
        },

        //Inventory
        getInventory({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.getInventory(params)
                .then(({data}) => {
                    console.log(data)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_INVENTORY', data);
                });
        },
        exportInventory({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            service.exportInventory(params)
            commit('HIDE_LOADING', null, {root: true})
        },
        exportInventoryById({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            service.exportInventoryById(params).then(()=>{
                commit('HIDE_LOADING', null, {root: true})
            })
                .catch((e)=>{
                    console.log(e)
                    commit('HIDE_LOADING', null, {root: true})
                    //return Promise.reject(e);
                });
        },
        exportClearTagById({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            service.exportClearTagById(params).then(()=>{
                commit('HIDE_LOADING', null, {root: true})
            })
                .catch((e)=>{
                    console.log(e)
                    commit('HIDE_LOADING', null, {root: true})
                    //return Promise.reject(e);
                });
        },

        //Clear Tag
        getClearTag({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            return service.getClearTag(params)
                .then(({data}) => {
                    //console.log(data.data)
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_CLEAR_TAGS', data.data.products);
                })
                .catch(()=>{
                    commit('HIDE_LOADING', null, {root: true})
                    commit('SET_CLEAR_TAGS', []);
                });
        },
        exportClearTag({commit}, params) {
            commit('SHOW_LOADING', null, {root: true})
            service.exportClearTag(params)
                .then(()=>{
                    commit('HIDE_LOADING', null, {root: true})
                })
                .catch((e)=>{
                    console.log(e)
                    commit('HIDE_LOADING', null, {root: true})
                    //return Promise.reject(e);
                });
        },
    },
    mutations: {
        SET_STOCK_IN: (state, list) => {
            state.stockIns = list;
        },
        SET_STOCK_OUT: (state, model) => {
            state.stockOuts = model;
        },
        SET_INVENTORY: (state, list) => {
            state.inventories = list;
        },
        SET_CLEAR_TAGS: (state, list) => {
            state.clearTags = list;
        },

    },
    getters: {
        stockIns: state => state.stockIns,
        stockOuts: state => state.stockOuts,
        inventories: state => state.inventories,
        clearTags: state => state.clearTags,
    }
}

