
export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    //console.log(user)
    if (user && user.access_token) {
      return {
          Authorization: 'Bearer ' + user.access_token ,
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Access-Control-Allow-Origin': '*'
      };
    } else {
      return {};
    }
}

export function authHeaderOnly() {
    let user = JSON.parse(localStorage.getItem('user'));
    //console.log(user)
    if (user && user.access_token) {
        return {
            Authorization: 'Bearer ' + user.access_token
        };
    } else {
        return {};
    }
}