import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'

//import middleware
import auth from "../middleware/auth";
import guest from "../middleware/guest";

// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

import PasswordReset from "src/components/Dashboard/Views/Password/Reset.vue";
import PasswordEmail from "src/components/Dashboard/Views/Password/Email.vue";

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')

// Example pages
//const UserProfile = () => import('src/components/Dashboard/Views/Examples/UserProfile.vue');



const LicenseListPage = () => import("../components/Dashboard/Views/License/LicenseListPage.vue")
const LicenseInputPage = () => import("../components/Dashboard/Views/License/LicenseInputPage.vue")

// // User Management
const RoleListPage = () => import("src/components/Dashboard/Views/SystemMgt/RoleListPage.vue");
const RoleInputPage = () => import("src/components/Dashboard/Views/SystemMgt/RoleInputPage.vue");
const UserListPage = () => import("src/components/Dashboard/Views/SystemMgt/UserListPage.vue");
const UserInputPage = () => import("src/components/Dashboard/Views/SystemMgt/UserInputPage.vue");

const WarehouseInputPage = () => import("src/components/Dashboard/Views/Warehouse/WarehouseInputPage");
const WarehouseListPage = () => import("src/components/Dashboard/Views/Warehouse/WarehouseListPage")

const BranchListPage = () => import("src/components/Dashboard/Views/Branch/BranchListPage")

const StoreListPage = () => import("src/components/Dashboard/Views/Store/StoreListPage")
const StoreInputPage = () => import("src/components/Dashboard/Views/Store/StoreInputPage")

const CategoryListPage = () => import("src/components/Dashboard/Views/Category/CategoryListPage")
const CategoryInputPage = () => import("src/components/Dashboard/Views/Category/CategoryInputPage")

const UnitListPage = () => import("src/components/Dashboard/Views/Unit/UnitListPage")
const UnitInputPage = () => import("src/components/Dashboard/Views/Unit/UnitInputPage")

const ItemListPage = () => import("src/components/Dashboard/Views/Item/ItemListPage")
const ItemInputPage = () => import("src/components/Dashboard/Views/Item/ItemInputPage")

const StockInListPage = () => import("src/components/Dashboard/Views/StockIn/StockInListPage")
const StockInInputPage = () => import("src/components/Dashboard/Views/StockIn/StockInInputPage")

const StockOutListPage = () => import("src/components/Dashboard/Views/StockOut/StockOutListPage")
const StockOutInputPage = () => import("src/components/Dashboard/Views/StockOut/StockOutInputPage")

const InventoryListPage = () => import("src/components/Dashboard/Views/Inventory/InventoryListPage")
const InventoryInputPage = () => import("src/components/Dashboard/Views/Inventory/InventoryInputPage")

const ClearTagListPage = () => import("src/components/Dashboard/Views/ClearTag/ClearTagListPage")
const ClearTagInputPage = () => import("src/components/Dashboard/Views/ClearTag/ClearTagInputPage")

const MoveListPage = () => import("src/components/Dashboard/Views/Move/MoveListPage")
const MoveInputPage = () => import("src/components/Dashboard/Views/Move/MoveInputPage")

const PriceListPage = () => import("src/components/Dashboard/Views/Price/PriceListPage")
const PriceInputPage = () => import("src/components/Dashboard/Views/Price/PriceInputPage")

const ItemEanListPage = () => import("src/components/Dashboard/Views/ItemEan/ItemEanListPage")
const ItemEanDetailPage = () => import("src/components/Dashboard/Views/ItemEan/ItemEanDetailPage")

const CustomerListPage = () => import("src/components/Dashboard/Views/Customer/CustomerListPage")
const CustomerInputPage = () => import("src/components/Dashboard/Views/Customer/CustomerInputPage")

const SupplierListPage = () => import("src/components/Dashboard/Views/Supplier/SupplierListPage")
const SupplierInputPage = () => import("src/components/Dashboard/Views/Supplier/SupplierInputPage")

const SaleListPage = () => import("src/components/Dashboard/Views/Sale/SaleListPage")
const SaleInputPage = () => import("src/components/Dashboard/Views/Sale/SaleNewPage")

const StockInReportPage = () => import("src/components/Dashboard/Views/Report/StockInReportPage")
const StockOutReportPage = () => import("src/components/Dashboard/Views/Report/StockOutReportPage")
const GeneralReportPage = () => import("src/components/Dashboard/Views/Report/GeneralReportPage")
const InventoryReportPage = () => import("src/components/Dashboard/Views/Report/InventoryReportPage")
const ShiftCheckReportPage = () => import("src/components/Dashboard/Views/Report/ShiftCheckReportPage")

const Privacy = () => import("src/components/Dashboard/Views/SystemMgt/Privacy")

let componentsMenu = {
    path: '/components',
    component: DashboardLayout,
    redirect: '/components/buttons',
    children: [
        {
            path: 'buttons',
            name: 'Buttons',
            component: Buttons
        },
        {
            path: 'grid-system',
            name: 'Grid System',
            component: GridSystem
        },
        {
            path: 'panels',
            name: 'Panels',
            component: Panels
        },
        {
            path: 'sweet-alert',
            name: 'Sweet Alert',
            component: SweetAlert
        },
        {
            path: 'notifications',
            name: 'Notifications',
            component: Notifications
        },
        {
            path: 'icons',
            name: 'Icons',
            component: Icons
        },
        {
            path: 'typography',
            name: 'Typography',
            component: Typography
        }

    ]
}

let tablesMenu = {
    path: '/table-list',
    component: DashboardLayout,
    redirect: '/table-list/regular',
    children: [
        {
            path: 'regular',
            name: 'Regular Tables',
            component: RegularTables
        },
    ]
}

let mapsMenu = {
    path: '/maps',
    component: DashboardLayout,
    redirect: '/maps/google',
    children: [
        {
            path: 'google',
            name: 'Google Maps',
            component: GoogleMaps
        }
    ]
}

let pagesMenu = {
    path: '/pages',
    component: DashboardLayout,
    redirect: '/pages/user',
    children: [
        {
            path: 'user',
            name: 'User Page',
            component: User
        },
        {
            path: 'timeline',
            name: 'Timeline Page',
            component: TimeLine
        }
    ]
}

let loginPage = {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {middleware: guest}
}

let registerPage = {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {middleware: guest}
}

let lockPage = {
    path: '/lock',
    name: 'Lock',
    component: Lock
}

let forgotPassword = {
    path: "/password/reset",
    name: "Password Reset",
    component: PasswordReset,
    meta: {middleware: guest}
}

let resetPassword = {
    path: "/password/email",
    name: "Password Reset",
    component: PasswordEmail,
    meta: {middleware: guest}
}

let examplesMenu = {
    path: "/system-management",
    component: DashboardLayout,
    name: "System",
    children: [
        {
            path: "user-role",
            name: "User Role",
            components: {default: RoleListPage},
            meta: {middleware: auth}
        },
        {
            path: "input-role",
            name: "Input Role",
            components: {default: RoleInputPage},
            meta: {middleware: auth}
        },
        {
            path: "user-list",
            name: "Users List",
            components: {default: UserListPage},
            meta: {middleware: auth}
        },
        {
            path: "user-input",
            name: "Users Input",
            components: {default: UserInputPage},
            meta: {middleware: auth}
        }
    ]
};

let licenseMenu = {
    path: "/license",
    component: DashboardLayout,
    name: "License",
    children: [
        {
            path: "license-list",
            name: "License List",
            components: {default: LicenseListPage},
            meta: {middleware: auth}
        },
        {
            path: "license-input",
            name: "License Input",
            components: {default: LicenseInputPage},
            meta: {middleware: auth}
        }
    ]
};

let warehouseMenu = {
    path: "/warehouse",
    component: DashboardLayout,
    name: "Warehouse",
    children: [
        {
            path: "warehouse-list",
            name: "Warehouse List",
            components: {default: WarehouseListPage},
            meta: {middleware: auth}
        },
        {
            path: "warehouse-input",
            name: "Warehouse Input",
            components: {default: WarehouseInputPage},
            meta: {middleware: auth}
        }
    ]
};

let storeMenu = {
    path: "/store",
    component: DashboardLayout,
    name: "Store",
    children: [
        {
            path: "store-list",
            name: "Store List",
            components: {default: StoreListPage},
            meta: {middleware: auth}
        },
        {
            path: "store-input",
            name: "Store Input",
            components: {default: StoreInputPage},
            meta: {middleware: auth}
        }
    ]
};

let branchMenu = {
    path: "/branch",
    component: DashboardLayout,
    name: "Branch",
    children: [
        {
            path: "branch-list",
            name: "Branch List",
            components: {default: BranchListPage},
            meta: {middleware: auth}
        }
    ]
};

let categoryMenu = {
    path: "/category",
    component: DashboardLayout,
    name: "Category",
    children: [
        {
            path: "category-list",
            name: "Category List",
            components: {default: CategoryListPage},
            meta: {middleware: auth}
        },
        {
            path: "category-input",
            name: "Category Input",
            components: {default: CategoryInputPage},
            meta: {middleware: auth}
        }
    ]
};

let unitMenu = {
    path: "/unit",
    component: DashboardLayout,
    name: "Unit",
    children: [
        {
            path: "unit-list",
            name: "Unit List",
            components: {default: UnitListPage},
            meta: {middleware: auth}
        },
        {
            path: "unit-input",
            name: "Init Input",
            components: {default: UnitInputPage},
            meta: {middleware: auth}
        }
    ]
};

let itemMenu = {
    path: "/item",
    component: DashboardLayout,
    name: "Item",
    children: [
        {
            path: "item-list",
            name: "Item List",
            components: {default: ItemListPage},
            meta: {middleware: auth}
        },
        {
            path: "item-input",
            name: "Item Input",
            components: {default: ItemInputPage},
            meta: {middleware: auth}
        }
    ]
};

let itemEanMenu = {
    path: "/ean",
    component: DashboardLayout,
    name: "Item Ean",
    children: [
        {
            path: "ean-list",
            name: "Ean List",
            components: {default: ItemEanListPage},
            meta: {middleware: auth}
        },
        {
            path: "ean-detail",
            name: "Ean Detail",
            components: {default: ItemEanDetailPage},
            meta: {middleware: auth}
        }
    ]
};

let stockInMenu = {
    path: "/stockin",
    component: DashboardLayout,
    name: "StockIn",
    children: [
        {
            path: "stockin-list",
            name: "StockIn List",
            components: {default: StockInListPage},
            meta: {middleware: auth}
        },
        {
            path: "stockin-input",
            name: "StockIn Input",
            components: {default: StockInInputPage},
            meta: {middleware: auth}
        }
    ]
};

let stockOutMenu = {
    path: "/stockout",
    component: DashboardLayout,
    name: "StockOut",
    children: [
        {
            path: "stockout-list",
            name: "StockOut List",
            components: {default: StockOutListPage},
            meta: {middleware: auth}
        },
        {
            path: "stockout-input",
            name: "StockOut Input",
            components: {default: StockOutInputPage},
            meta: {middleware: auth}
        }
    ]
};

let moveMenu = {
    path: "/move",
    component: DashboardLayout,
    name: "Move",
    children: [
        {
            path: "move-list",
            name: "Move List",
            components: {default: MoveListPage},
            meta: {middleware: auth}
        },
        {
            path: "move-input",
            name: "Move Input",
            components: {default: MoveInputPage},
            meta: {middleware: auth}
        }
    ]
};

let inventoryMenu = {
    path: "/inventory",
    component: DashboardLayout,
    name: "Inventory",
    children: [
        {
            path: "inventory-list",
            name: "Inventory List",
            components: {default: InventoryListPage},
            meta: {middleware: auth}
        },
        {
            path: "inventory-input",
            name: "Inventory Input",
            components: {default: InventoryInputPage},
            meta: {middleware: auth}
        }
    ]
};

let clearTagMenu = {
    path: "/clear-tag",
    component: DashboardLayout,
    name: "ClearTag",
    children: [
        {
            path: "clear-tag-list",
            name: "clear-tag List",
            components: {default: ClearTagListPage},
            meta: {middleware: auth}
        },
        {
            path: "clear-tag-input",
            name: "clear-tag Input",
            components: {default: ClearTagInputPage},
            meta: {middleware: auth}
        }
    ]
};

let priceMenu = {
    path: "/price",
    component: DashboardLayout,
    name: "Price",
    children: [
        {
            path: "price-list",
            name: "Price List",
            components: {default: PriceListPage},
            meta: {middleware: auth}
        },
        {
            path: "price-input",
            name: "Price Input",
            components: {default: PriceInputPage},
            meta: {middleware: auth}
        }
    ]
};

let customerMenu = {
    path: "/customer",
    component: DashboardLayout,
    name: "Customer",
    children: [
        {
            path: "customer-list",
            name: "Customer List",
            components: {default: CustomerListPage},
            meta: {middleware: auth}
        },
        {
            path: "customer-input",
            name: "Customer Input",
            components: {default: CustomerInputPage},
            meta: {middleware: auth}
        }
    ]
};

let supplierMenu = {
    path: "/supplier",
    component: DashboardLayout,
    name: "Supplier",
    children: [
        {
            path: "supplier-list",
            name: "Supplier List",
            components: {default: SupplierListPage},
            meta: {middleware: auth}
        },
        {
            path: "supplier-input",
            name: "Supplier Input",
            components: {default: SupplierInputPage},
            meta: {middleware: auth}
        }
    ]
};

let saleMenu = {
    path: "/sale",
    component: DashboardLayout,
    name: "Sale",
    children: [
        {
            path: "sale-list",
            name: "Sale List",
            components: {default: SaleListPage},
            meta: {middleware: auth}
        },
        {
            path: "sale-input",
            name: "Sale Input",
            components: {default: SaleInputPage},
            meta: {middleware: auth}
        }
    ]
};

let reportMenu = {
    path: "/report",
    component: DashboardLayout,
    name: "Report",
    children: [
        {
            path: "stock-in",
            name: "StockIn Report",
            components: {default: StockInReportPage},
            meta: {middleware: auth}
        },
        {
            path: "stock-out",
            name: "StockOut Report",
            components: {default: StockOutReportPage},
            meta: {middleware: auth}
        },
        {
            path: "general",
            name: "General Report",
            components: {default: GeneralReportPage},
            meta: {middleware: auth}
        },
        {
            path: "inventory-rpt",
            name: "Inventory Report",
            components: {default: InventoryReportPage},
            meta: {middleware: auth}
        },
        {
            path: "shift-check-rpt",
            name: "Shift Check Report",
            components: {default: ShiftCheckReportPage},
            meta: {middleware: auth}
        },
    ]
};

const routes = [
    {
        path: '/',
        component: DashboardLayout,
        redirect: '/admin/overview',
        children: [
            {
                path: 'charts',
                name: 'Charts'
            }
        ]
    },
    componentsMenu,
    tablesMenu,
    mapsMenu,
    pagesMenu,
    loginPage,
    registerPage,
    lockPage,
    forgotPassword,
    resetPassword,
    examplesMenu,
    licenseMenu,
    warehouseMenu,
    branchMenu,
    storeMenu,
    categoryMenu,
    unitMenu,
    itemMenu,
    stockInMenu,
    stockOutMenu,
    moveMenu,
    inventoryMenu,
    clearTagMenu,
    priceMenu,
    itemEanMenu,
    customerMenu,
    supplierMenu,
    saleMenu,
    reportMenu,
    {
        path: '/admin',
        component: DashboardLayout,
        redirect: '/admin/overview',
        meta: {middleware: auth},
        children: [
            {
                path: 'overview',
                name: 'Overview',
                component: Overview,
                meta: {middleware: auth},
            },
            {
                path: 'widgets',
                name: 'Widgets',
                component: Widgets,
                meta: {middleware: auth},
            }
        ]
    },
    {
        path: '/privacy',
        component: Privacy
    },
    {path: '/:pathMatch(.*)', name: 'not-found', component: NotFound}
];
/*router.resolve({
  name: 'not-found',
  params: { pathMatch: ['not', 'found'] },
}).href*/
export default routes
